<template>
  <div v-if="inited">
    <ContextTitle :title="title" :passedActions="titleBarActions" />
    <div v-if="posPupulated">
      <LineItem>
        <span class="edit-bar" />
      </LineItem>

      <DatabasePicker
        :additionalInputs="true"
        :activeItems="activeItems"
        :items="items"
        :struct="struct"
        :searchProps="searchProps"
        class="search-list col-12"
        @selected="handleItemClick"
      >
        <div v-if="superuser" slot="additionalinputs" class="col-12">
          <div class="row additional-row">
            <select-input
              v-model="category"
              :options="categoryOptions"
              label="Category"
            />
          </div>
          <div class="row additional-row" v-if="pos == 'omnivore' || pos == 'infogenesis' || pos == 'oracle'">
            <div>Select Multiple Sizes</div>
            <toggle v-model="selectMultipleSizes" />
          </div>
        </div>
      </DatabasePicker>
    </div>
    <div v-else class="refresh-container col12">
      It doesn't appear that this store has any POS Inventory Items. Try refreshing below in store settings menu functions.
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { toggleItem } from "helpers";
import { OMNI_posSizeIDIfExists } from "helpers/products";
import LineItem from "../components/line_item.vue";
import DatabasePicker from "components/NewDatabasePicker";
import ContextTitle from "../components/context_title.vue";
import { removeDups } from "helpers";
import Toggle from "components/cleverly/Toggle";
import clone from "clone";
import _ from "lodash";
import SelectInput from "components/cleverly/SelectInput.vue";

export default {
    name: "StoreProductAddFromDatabase",
    components: {
        LineItem,
        DatabasePicker,
        ContextTitle,
        Toggle,
        SelectInput,
    },
    data() {
        return {
            searchProps: ["pos_productID", "pos_productName", "pos_productCategory"],
            title: [
                { to: { name: "store-products" }, label: "Products" },
                "Select product from database",
            ],
            activeItems: [],
            struct: {
                title: {
                    value: (item) => item.pos_productName,
                },
                sku: {
                    display: "Item ID",
                    value: (item) => item.pos_productID,
                },
                price: {
                    display: "Price",
                    value: this.priceItem,
                },
                path: {
                    display: "Path",
                    value: (item) => item.pos_productCategory,
                },
            },
            category: "",
            selectMultipleSizes: false,
        };
    },
    computed: {
        posPupulated() {
            if (this.storeReloadMap[this.$route.params.store] === undefined) {
                return this.posProducts.length > 0
            }
            return this.posProducts.length > 0 && !this.storeReloadMap[this.$route.params.store].complete;
        },
        titleBarActions() {
            const cancel = {
                type: "cancel",
                display: "Cancel",
                run: this.toolbarCancel,
            };

            const save = {
                type: "proceed",
                display: "Add",
                run: this.toolbarSave,
            };

            if (!this.activeItems.length) {
                return [cancel];
            }

            return [cancel, save];
        },
        items() {
            return removeDups(
                this.posProducts.map((el) => {
                    return Object.assign(el, { id: el.pos_productID });
                }),
                "id"
            );
        },
        categoryOptions() {
            let initial = [
            ];
            return initial.concat(
                this.productCategories.map((category) => {
                    return {
                        text: category.inventoryTitleDescription,
                        value: category.inventoryTitleDescription,
                    };
                })
            );
        },
        ...mapGetters([
            "inited",
            "posProducts",
            "pos",
            "storeReloadMap",
            "store",
            "productCategories",
            "user",
            "superuser"
        ]),
    },

    methods: {
        handleItemClick(item) {
            this.activeItems = toggleItem(this.activeItems, item.id);
        },

        save() {
            if (!this.selectMultipleSizes) {
                return this.saveMultipleProducts();
            } else {
                return this.saveMultipleSizes();
            }
        },
        saveMultipleSizes() {
            if (this.activeItems.length < 2) {
                this.toast(
                    "Please select at least two items to create a combined item.",
                    "error"
                );
                return false;
            }

            var allSizes = _.orderBy(
                this.activeItems.map((el) => {
                    let item = this.posProducts.find(
                        (product) => product.pos_productID === el
                    );
                    const sizes = item.pos_productSizes;
                    const cost = sizes.length ? sizes[0].cost : 0;
                    return {
                        ...item,
                        cost,
                    };
                }),
                ["cost"],
                ["asc"]
            );

            var existingProductNames = [];

            for (let index = 0; index < allSizes.length; index++) {
                var element = allSizes[index];

                if (existingProductNames.indexOf(element.pos_productName) == -1) {
                    existingProductNames.push(element.pos_productName);
                    continue;
                }

                element.pos_productName = `${element.pos_productName} ${index}`;
            }

            let primaryItem = allSizes[0];
            const sizeID =
        this.pos === "omnivore" ? OMNI_posSizeIDIfExists(primaryItem) : "";
            let payload = {
                name: primaryItem.pos_productName,
                pos_productID: primaryItem.pos_productID,
                category: this.category == "" ? primaryItem.pos_productCategory : this.category,
                cost: primaryItem.cost,
                inventoryItemSubs: allSizes.map((el) => {
                    const sizeID = OMNI_posSizeIDIfExists(el);
                    const sizes = el.pos_productSizes;
                    const cost = sizes.length ? sizes[0].cost : 0;
                    let sub = {
                        inventoryItemSubName: el.pos_productName,
                        pos_sizeID: sizeID,
                        pos_productID: el.pos_productID,
                        cost,
                    };
                    return sub;
                }),
            };
            return this.saveProducts({ items: [payload], pos: this.pos });
            return true;
        },
        saveMultipleProducts() {
            const payload = this.activeItems
                .map((el) => {
                    const item = this.posProducts.find(
                        (product) => product.pos_productID === el
                    );
                    if (!item) {
                        return false;
                    }
                    const sizes = item.pos_productSizes;
                    const cost = sizes.length ? sizes[0].cost : 0;
                    const sizeID = OMNI_posSizeIDIfExists(item);
                    return {
                        name: item.pos_productName,
                        pos_productID: item.pos_productID,
                        category: this.category == "" ? item.pos_productCategory : this.category,
                        cost,
                        pos_sizeID: sizeID,
                    };
                })
                .filter((el) => el);

            return this.saveProducts({ items: payload, pos: this.pos });
            return true;
        },
        toolbarSave() {
            this.save().then(() => {
                this.$router.push({ name: "store-products" });
                this.activeItems = [];
            });
        },
        toolbarCancel() {
            this.$router.push({ name: "store-products" });
        },
        priceItem(item) {
            const sizes = item.pos_productSizes;

            if (!sizes.length) {
                return "N/A";
            }
            var formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            });
            return `${this.store.currencySymbol}${Number(sizes[0].cost).toFixed(2)}`;

            return "$" + sizes[0].cost;
        },
        ...mapActions(["saveProducts"]),
    },
};
</script>

<style lang="scss" scoped>
@import "~scss/mixins";

.refresh-container {
  text-align: center;
  padding-top: 10px;
}
.search-list {
  height: 600px;
}
.heading {
  padding-left: 20px;
  margin-top: 10px;
  font-weight: 700;
}
.toggle {
  margin-left: 15px;
}
.additional-row {
  margin: 10px;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.inited
    ? _c(
        "div",
        [
          _c("ContextTitle", {
            attrs: { title: _vm.title, passedActions: _vm.titleBarActions },
          }),
          _vm.posPupulated
            ? _c(
                "div",
                [
                  _c("LineItem", [_c("span", { staticClass: "edit-bar" })]),
                  _c(
                    "DatabasePicker",
                    {
                      staticClass: "search-list col-12",
                      attrs: {
                        additionalInputs: true,
                        activeItems: _vm.activeItems,
                        items: _vm.items,
                        struct: _vm.struct,
                        searchProps: _vm.searchProps,
                      },
                      on: { selected: _vm.handleItemClick },
                    },
                    [
                      _vm.superuser
                        ? _c(
                            "div",
                            {
                              staticClass: "col-12",
                              attrs: { slot: "additionalinputs" },
                              slot: "additionalinputs",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "row additional-row" },
                                [
                                  _c("select-input", {
                                    attrs: {
                                      options: _vm.categoryOptions,
                                      label: "Category",
                                    },
                                    model: {
                                      value: _vm.category,
                                      callback: function ($$v) {
                                        _vm.category = $$v
                                      },
                                      expression: "category",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.pos == "omnivore" ||
                              _vm.pos == "infogenesis" ||
                              _vm.pos == "oracle"
                                ? _c(
                                    "div",
                                    { staticClass: "row additional-row" },
                                    [
                                      _c("div", [
                                        _vm._v("Select Multiple Sizes"),
                                      ]),
                                      _c("toggle", {
                                        model: {
                                          value: _vm.selectMultipleSizes,
                                          callback: function ($$v) {
                                            _vm.selectMultipleSizes = $$v
                                          },
                                          expression: "selectMultipleSizes",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              )
            : _c("div", { staticClass: "refresh-container col12" }, [
                _vm._v(
                  " It doesn't appear that this store has any POS Inventory Items. Try refreshing below in store settings menu functions. "
                ),
              ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }